.form{
    margin: 1.25rem 0 2rem;
    &__wrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &__input{
        margin: 1.25rem 0 0;
        padding: 0.9rem;
        height: 2.5rem;
        font-size: 0.9rem;
        border: 1px solid #eeeeee;
        &:focus{
            outline: none;
        }
        &--name, &--email{
            flex-basis: 40%;
            @media screen and (max-width:768px) {
                flex-basis: 100%;
            }
        }
        &--textarea{
            flex-basis: 100%;
            height: 15vw;
            margin-bottom: 2rem;
            @media screen and (max-width:768px) {
                height:20vh;
            }
        }
    }
    &__button{
        padding: 0.9rem 3rem;
        border: none;
        border-radius: 4px;
        text-transform: uppercase;
        outline: none;
        cursor:pointer;
    }
}