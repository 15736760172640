.information {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  &__desc {
    line-height: 1.8;
    margin: 1.5rem 0;
    text-align: justify;
    font-size: 0.9rem;
  }
  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (max-width:768px) {
      flex-direction: column;
    }
  }
  li {
    flex-basis: 50%;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    display: flex;
    flex-direction: row;
  }
  &__title{
        text-transform: capitalize;
        flex-basis:65%;
    }
}
