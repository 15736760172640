.blog {
  &__box {
    position: relative;
    overflow: hidden;
    border-radius: 0.3rem;
    height: 25rem;
    margin-bottom: 0.5rem;
    width: 49%;
  }
  img {
    max-width: 100%;
    display: block;
    object-fit:contain;
    height: auto;
  }
  &__thumb {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    padding: 1rem;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6));
      z-index: 2;
    }
  }
  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &__content {
    position: relative;
    z-index: 5;
    color: #f5f5f5;
    transition: all 0.3s ease-in-out;
  }
  &__title {
    font-size: 1.2rem;
    line-height: 1;
    margin-bottom: 0.5rem;
  }
  &__metas {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &-item {
      font-size: 1rem;
      margin-right: 2rem;
      display: flex;
      align-items: center;
      margin-bottom: 1.2rem;
    }
    &-icon {
      font-size: 1.1rem;
      line-height: 1;
      margin-right: 0.5rem;
    }
  }
  &__body {
    padding: 0.4rem;
    background-color: #f5f5f5;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 100;
    bottom: -100%;
    transition: bottom 0.3s ease-in-out;
  }
  &__box:hover &__body{
      bottom: 0;
  }

  &__box:hover &__content{
      transform: translateY(-12rem);
  }
  &__category {
    padding: 0.3rem;
    border: 0.1rem solid #ECBBA6;
    display: inline-block;
    border-radius: 0.2rem;
    color:#E96F38;
    font-size: 0.5rem;
    margin: 0.5rem 0;
  }
  &__excerpt {
    margin: 1rem 0;
    text-align: justify;

    p {
      font-size: 0.9rem;
      font-weight: 200;
    }
  }
  &__btn {
    text-decoration: none;
    display: block;
    padding: 0.5rem;
    background-color: #8636ab;
    color: #f5f5f5;
    border-radius: 0.2rem;
    text-align: center;
    text-transform: capitalize;
    transition: background-color 0.3s ease-in-out;
    &:hover{
      background-color: #6d2c8c;
    }
  }
}
