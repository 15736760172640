.nav {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  @media screen and (max-width:768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  

  &-item {
    position: relative;
    padding:0.9rem 0.4rem;
    @media screen and (max-width:768px) {
      flex-basis: 20%;
    }
  }
  &-link {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    align-items: center;
    color: #323232;
     &::before {
      content: "";
      position: absolute;
      bottom: 0;
      height: 1px;
      width: 100%;
      background: radial-gradient(
        ellipse at right,
        #dddddd 0%,
        rgba(255, 255, 255, 0) 70%
      );
      @media screen and (max-width:768px) {
        height: 100%;
        width: 1px;
        top: -10%;
        left:0;
      }
    }
  }

  &-text,
  &-icon {
    font-size: 0.79rem;
    font-weight: 500;
    text-transform:uppercase;
  }
  &-icon {
    font-size: 1.1rem;
  }
  &-link:hover, &-active{
      color: #F07038;
  }
}
