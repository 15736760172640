@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body {
  background: #eaeced;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15vh 0 0;
  @media screen and (max-width:768px) {
    margin: 0;
  }
}
.row {
  max-width: 1320px;
  max-height: 700px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width:768px) {
    flex-direction: column;
  }
}
.header {
  flex-basis: 5%;
  position: relative;
  top: 15px;
  margin-right: 8px;
  z-index: 100;
  @media screen and (max-width:768px) {
    top: 0;
    margin-right: 0;
  }
}
.card {
  flex-basis: 35%;
  height: 100%;
  position: relative;
  z-index: 11;
  border-radius: 4px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 10px 10px 15px rgba($color: #000000, $alpha: 0.05);
    border-radius: 0 0 4px 0;
  }
  &::after {
    content: "";
    position: absolute;
    left: -15px;
    top: -15px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    z-index: 10;
    background: linear-gradient(
      135deg,
      rgba(240, 112, 56, 0.4) 0%,
      rgba(240, 112, 56, 0.01) 100%
    );
  }
}
.content {
  flex-basis: 60%;
  padding: 0;
  position: relative;
  overflow: hidden;
  border-radius: 0 4px 4px 0;
  @media screen and (max-width:768px) {
    overflow: visible;
  }
}
.main {
  width: 100%;
  position: absolute;
  overflow: hidden;
  top: 15px;
  bottom: 15px;
  border-radius: 0 4px 4px 0;
  z-index: 10;
  transition: visibility 1s linear, opacity 0s linear;
  background: #ffffff;
  transform-origin: center left;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 20px;
    height: 20px;
    width: 100%;
    background: #ffffff;
    z-index: 10;
  }
  &::after {
    content: "";
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    right: 20px;
    height: 20px;
    width: 100%;
    background: #ffffff;
    z-index: 10;
  }
  @media screen and (max-width:768px) {
    overflow: visible;
    position: relative;
  }
}
.wrapper {
  padding: 30px 30px 0 30px;
  position: relative;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  @media screen and (max-width:768px) {
    overflow: visible;
  }
}
