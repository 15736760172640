.star {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0 1.9rem;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    top: 0;
    left: 0;
    background: radial-gradient(
      ellipse at top,
      #dddddd 0%,
      rgba(255, 255, 255, 0) 70%
    );
  }
  &__bar {
    color: #c5c5c5;
    font-size: 25px;
    height: 25px;
    width: 225px;
    position: relative;
    padding: 0;
    text-shadow: 0px 1px 0 #a2a2a2;
  }
  &__tp {
    color: #e7711b;
    padding: 0;
    position: absolute;
    z-index: 1;
    display: flex;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  &__bt {
    padding: 0;
    display: flex;
    z-index: 0;
  }
}
