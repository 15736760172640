.profile {
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 12;
  &__image {
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      left: -25%;
      bottom: -63%;
      width: 100%;
      height: 70%;
      background: #ffffff;
      transform: rotate(12deg);
    }
    &::after {
      content: "";
      position: absolute;
      left: auto;
      right: -25%;
      bottom: -63%;
      width: 100%;
      height: 70%;
      background: #ffffff;
      transform: rotate(-8deg);
    }
  }
  &__name {
    font-size: 34px;
    margin: 20px 0 10px 0;
    color: #323232;
    line-height: 32px;
    font-weight: 400;
    text-align: center;
  }
  &__position {
    position: relative;
    margin: 0;
    display: inline-block;
    font-size: 18px;
    line-height: 20px;
    height: 20px;
    font-weight: 500;
  }
  &__social {
    margin-top: 10px;
    margin-bottom: 100px;
    @media (max-width: 768px) {
      margin-bottom: 45px;
    }
    ul {
      list-style: none;
      display: flex;
      flex-direction: row;
    }
    li {
      padding: 10px;
      font-size: 1.2rem;
    }
    li a {
      color: #323232;
    }
    li a:hover {
      color: #78cc6d;
    }
  }
  &__download {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: center;
    padding: 20px 0px 10px;
    width: 100%;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 1px;
      background: radial-gradient(
        ellipse at center,
        #dddddd 0%,
        rgba(255, 255, 255, 0) 70%
      );
    }
    a {
      text-transform: uppercase;
      text-decoration: none;
      font-size: 1rem;
      font-weight: 600;
      color: #323232;
    }
  }
  &__icon {
    line-height: 2.1rem;
    font-size: 1.2rem;
    margin-left: 10px;
  }
  .Typewriter__cursor{
    display: none;
  }
}
