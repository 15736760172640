.resume {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  margin: 2rem 0;
  @media screen and (max-width:768px) {
    flex-direction: column;
  }
  &__col {
    flex-basis: 48%;
    &:nth-child(2){
        position: relative;
    }
    &:nth-child(2)::before {
      content: "";
      position: absolute;
      left:-14px;
      top: -30px;
      width: 1px;
      height: 100%;
      background: radial-gradient(
        ellipse at top,
        #dddddd 0%,
        rgba(255, 255, 255, 0) 70%
      );
      @media screen and (max-width:768px) {
        display: none;
      }
    }
  }
  &__header {
    display: flex;
    flex-direction: row;
  }
  &__icon {
    color: #E96F38;
    font-size: 1.5rem;
  }
  &__title {
    font-size: larger;
    line-height: 1.7;
    margin-left: 0.5rem;
    text-transform: uppercase;
  }
  &__content{
    margin-top: 1rem;
  }
}
