.education {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0;
  margin: 1.25rem 0;
  position: relative;
  &__year {
    margin: 0.5rem 0;
    span {
      font-size: 0.8rem;
      border: 1px solid #000;
      border-radius: 4px;
      padding: 0.1rem 0.5rem;
      color: #000;
    }
  }
  &__institute {
    font-size: 1.145rem;
    font-weight: 500;
    margin-bottom: 0.2rem;
    color: #323232;
    text-transform: capitalize;
  }
  &__country {
    font-size: 1rem;
    color: #323232;
    font-weight: 400;
    margin-bottom: 0.2rem;
  }
  &__description {
    font-size: 1rem;
    line-height: 1.8em;
  }
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    top: 0;
    background: radial-gradient(
      ellipse at center,
      #dddddd 0%,
      rgba(255, 255, 255, 0) 70%
    );
  }
}
