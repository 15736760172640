.box {
  display: flex;
  flex-direction: column;
  &__header {
    position: relative;
    padding: 0 0 1.5rem 0;
    display: flex;
    justify-content: space-between;
    &::before {
      content: "";
      position: absolute;
      height: 2.5rem;
      width: 2.5rem;
      top: -0.3rem;
      left: -15px;
      border-radius: 30px;
      z-index: -1;
      background: linear-gradient(
        135deg,
        rgba(240, 112, 56, 0.4) 0%,
        rgba(240, 112, 56, 0.01) 100%
      );
    }
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      bottom: 0px;
      left: -28px;
      background: radial-gradient(
        ellipse at left,
        #dddddd 0%,
        rgba(255, 255, 255, 0) 80%
      );
      @media screen and (max-width: 768px) {
        left: -10px;
      }
    }
    &-title {
      flex-basis: 30%;
      font-size: 1.25rem;
      font-weight: 500;
      span {
        color: #e96f38;
      }
      @media screen and (max-width: 768px) {
        flex-basis: 100%;
      }
    }
    &-input {
      border-radius: 4px;
      border: 2px solid #ccc;
      font-size: 1rem;
      background-position: 5px 5px;
      padding: 3px 0 3px 30px;
      background-color: white;
      background-image: url("../images/Assets/searchicon.png");
      background-repeat: no-repeat;
      @media screen and (max-width: 768px) {
        width: 10px;
      }
      &:focus {
        outline: none;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
      &:hover {
        border-color: #e96f38;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
  &__content {
    ul {
      display: flex;
      flex-direction:column;
      flex-wrap: wrap;
      list-style: none;
      padding-top: 1rem;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }
    li {
      flex-basis: auto;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.9rem;
      @media screen and (max-width: 768px) {
        justify-content: unset;
        flex-direction: column;
      }
    }
  }
  &__body {
    margin-bottom: 1rem;
    .blog {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 2rem 0;
    }
  }
}
